<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800">
            <v-card flat class="card">
                <v-toolbar outlined flat >
                    <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
    
                <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                
                                <v-row>
                                    <aTextField v-model="item.name" title="الاسم" :cols="12" :md="6" :required="true" :fieldTitle="true"/>
                                    <aAutoComplete :loading="$global.state.loading" :fieldTitle="true" v-model="item.transactionType" :items="[{id: -1, name: 'سحب'}, {id: 1, name: 'ايداع'}]" item-text="name" item-value="id" title="نوع القيد" :cols="12" :md="6" :required="true" />
                                </v-row>
    
                            </v-container>
                        </v-card>
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
    </template>
    
    <script>
    export default {
    
        data() {
            return {
                item: {},
                search: '',
                valid: false,
            }
        },
    
        created() {
            this.reset()
            
            this.$eventBus.$on('fill-fields', () => {
                this.item = this.$store.state.itemDetails
            })
           
        },
    
        methods: {
    
            reset() {
                this.item = {
                    "name": "",
                    "transactionType": ""
                }
            },
    
            submit() {
                if (this.$store.state.itemDetails.dialogType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            },
    
            addItem() {
                this.$global.state.loading = true
                this.$http.post(`TransactionCategory`, this.item).then(res => {
                    this.$service.success('تم الإضافة بنجاح')
                    this.$eventBus.$emit(`${this.$route.path}`)
                    this.close()
                }).catch(err => {
                    this.$service.faild('حدث خطأ')
                    this.$service.faild(err.data.message)
                    this.$service.faild(JSON.stringify(err.data.result))
                }).finally(() => this.$global.state.loading = false)
            },
    
            editItem() {
                this.$global.state.loading = true
                this.$http.put(`TransactionCategory/${this.item.id}`, this.item).then(res => {
                    this.close()
                    this.$service.success('تم التعديل بنجاح')
                    this.$eventBus.$emit(`${this.$route.path}`)
                }).catch(err => {
                    this.$service.faild('حدث خطأ')
                    this.$service.faild(err.data.message)
                    this.$service.faild(JSON.stringify(err.data.result))
                }).finally(() => this.$global.state.loading = false)
            },
    
            close() {
                this.$store.commit('setDialog')
                this.reset()
                this.resetValidation()
                this.$store.commit('setItemDetails', {})
            },
    
            resetValidation() {
                this.$refs.form.resetValidation()
            },
    
        },
    }
    </script>
    
    